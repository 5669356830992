#gradient-canvas {
    width: 100%;
    --gradient-color-1: #a960ee;
    --gradient-color-2: #ff333d;
    --gradient-color-3: #90e0ff;
    --gradient-color-4: #ffcb57;
    z-index: 0;
  }
#gradient-canvas.enlarge{

}

#maarten{
    max-width: 200px;
    position: absolute; 
    bottom:-70%; 
    z-index:0;
    transition: 3s all;
    min-width: 50%;
    opacity: 0;
}
#maarten.show{
    bottom:0; 
    opacity: 1;

}

#mendix-loaded{
    transition: 2s all;
    opacity: 0;
}

#mendix-loaded.show{
    opacity: 1;
}

#SD25-Gate{
    z-index: 2;
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    height: 100%;
}


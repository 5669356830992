body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: 'brandon_grotesquebold';
  src: url('font/brandon_bld-webfont.woff2') format('woff2'),
  url('font/brandon_bld-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}
@import url('https://fonts.googleapis.com/css2?family=Space+Grotesk:wght@300&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Monsieur+La+Doulaise&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lavishly+Yours&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Rouge+Script&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Cookie&family=Meow+Script&family=Rouge+Script&family=Teko:wght@300..700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Barrio&display=swap');
.slide-out {
    animation: slide-out 3s;
  }

.slide-in{
    animation: slide-in 3s;
}

@keyframes slide-in {
    from {
      translate: 150vw 0;
      scale: 100% 1;
    }
  
    to {
      translate: 0 0;
      scale: 100% 1;
    }
}
@keyframes slide-out {
    from {
      translate: 0 0;
      scale: 100% 1;
    }
  
    to {
      translate: 150vw 0;
      scale: 100% 1;
    }
}

#access-container{
    position: absolute;
    bottom: 0;
    display: flex;
    flex-flow: column nowrap;
    max-width: 100vw;
    max-height: 70vh;
    z-index: 2;
    align-items: center;
}
#access-greeting{
    padding: 15px 75px 6px 0;
    padding-right: 70px;
    text-align: right;
}
#access-message{
    padding: 0 25px 0 11%;
}
#access-code{
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding-top: 2%;
    padding-left: 10%;
}

#maarten-access{
    max-width: 300px;
    object-fit: contain;
    overflow: hidden;
    bottom: 0;
}

#access-text-container{
    position: absolute;
    top: 2%;
}
#sd25-access-loader{
    position: absolute;
    width: 75px;
    height: 50px;
    object-fit: cover;
    top: 43%;
    right: -3%;
}
.vi{
    font-size: 1em;
    -webkit-text-size-adjust: none;
    text-size-adjust: none;
}

.vi__character{
    border: 2px solid #a96feb;
    border-radius: 10px;
    background-color: transparent;
    font-family: Teko;
    font-size: 1em;
    -webkit-text-size-adjust: none;
    text-size-adjust: none;
}
.vi__character--selected{
    border: 2px solid #fac65f;
    outline: 2px solid #fac65f;
    color: #fac65f;
    -webkit-text-size-adjust: none;
    text-size-adjust: none;
}

@media(max-width: 414px) {
    #access-text-container{
        top: 0%;
    }
    #sd25-access-loader{
        top: 37%;
    }
}

#sd25--access-praatwolk{
    width: 500px;
    max-width: 100vw;
    margin-bottom: -15px;

}
#loods25-background {
    position: fixed;
    right: 0;
    top: 0;
    min-width: 100vw;
  }

.eventix-shop{
    margin: 0 !important;
}

@media(max-width: 1046px){
    #loods25-background{
        min-width: 100vh;
    }
    .infobox{
        order: 1;
    }
    .shop-frame{
        order: 2;
    }
    #loods25-container{
        flex-direction: column;
    }

}
.steps span{
    color: "#C1FF72" !important;
}
#maarten-loading-container{
    position: absolute;
    bottom: 0;
    display: flex;
    flex-flow: column nowrap;
    max-width: 100vw;
    max-height: 60vh;
    z-index: 5;
    

}

#maarten-loading{
    max-width: 300px;
    object-fit: contain;
    overflow: hidden;
    bottom: 0;
}
#sd25-praatwolk{
    width: 500px;
    max-width: 100vw;
    margin-bottom: -15px;
}

#text-container{
    position: absolute;
    top: 3%;
}
#greeting{
    padding: 15px 75px 6px 0;
    padding-right: 70px;
    text-align: right;
}
#loading-message{
    padding: 0  25px 0 11%;
}


#sd25-loader{
    width: 150px;
    height: 50px;
    object-fit: cover;
    padding-left: 40%;
    z-index: 35;

}

h1{
    color: transparent;
    -webkit-background-clip: text; /* Edge, Chrome */
    background-clip: text; /* Safari, FF */
    background-image: 
      url(https://uploads.sitepoint.com/wp-content/uploads/2024/03/1710934391floral.jpg);
    background-repeat: no-repeat;
    background-size: 200%;
    animation: gradientAnimation 30s linear infinite;
    animation-direction: alternate;
    font-size: 1em;
    margin: 0;
    line-height: 1.3em;
    
}

@keyframes gradientAnimation {
    0% {
      background-position: 0;
    }
    to {
      background-position: 100%;
    }
  }

@media(max-width: 493px){
   h1{
    top: 0%

     
}}

@media(max-width: 460px) {
    h1{
        top: 0%;
        font-size: 0.9em;
       
        
    }
    
}
@media(max-width: 414px) {
    h1{
        top: 0%;
        font-size: 0.8em
        
    }
}
@media(max-width: 383px) {
    h1{
        top: 0%;
        font-size: 0.em
        
    }
}
@media(max-width: 362px) {
    h1{
        top: 0%;
        font-size: 0.75em
        
    }
}
@media(max-width: 350px) {
    h1{
        top: 0%;
        font-size: 0.7em
        
    }
}
@media(max-width: 324px) {
    h1{
        top: 0%;
        font-size: 0.65em
        
    }
}
@media(max-width: 307px) {
    h1{
        top: 0%;
        font-size: 0.60em
        
    }
}


.fade-in {
    animation: fade-in 3s;
  }
  
  @keyframes fade-in {
    from {
      opacity: 0;
    }
    to {
      opacity: 100;
    }
  }

.fade-out {
    opacity: 0;
    transition: all 2s linear 1s;
  }
:root {
    --illustration-drawing-speed: 2s;
  }
  
#loading-indicator path:not([fill="none"]) {
fill: transparent;
stroke-width: 1;
stroke: currentColor;
animation: draw 4s forwards;
animation-iteration-count: infinite;
}

#loading-indicator path:nth-child(1) {
    stroke-dasharray: 1150.2779541015625;
    stroke-dashoffset: 1150.2779541015625;
  }
#loading-indicator path.piece-b {
  animation: rotate-b 4s infinite;
  transform-origin: 0% 100%;
  fill: currentColor;
}
#loading-indicator path:nth-child(3) {
stroke-dasharray: 411.3184814453125;
stroke-dashoffset: 411.3184814453125;
}
#loading-indicator path.piece-t {
  animation: rotate-b 4s infinite;
  transform-origin: 0% 40%;
  fill: currentColor;
}
#loading-indicator path:nth-child(5) {
stroke-dasharray: 402.3777770996094;
stroke-dashoffset: 402.3777770996094;
}
#loading-indicator path.piece-n {
  animation: rotate-b 4s infinite;
  transform-origin: 200% 40%;
  fill: currentColor;
}
#loading-indicator path:nth-child(7) {
stroke-dasharray: 174.15943908691406;
stroke-dashoffset: 174.15943908691406;
}
#loading-indicator path.piece-t2 {
  animation: rotate-t2 4s infinite;
  transform-origin: center;
  fill: currentColor;
}
#loading-indicator path:nth-child(9) {
stroke-dasharray: 354.2406311035156;
stroke-dashoffset: 354.2406311035156;
}
#loading-indicator path:nth-child(10) {
stroke-dasharray: 244.40008544921875;
stroke-dashoffset: 244.40008544921875;
}
#loading-indicator path.piece-e {
  animation: rotate-e 4s infinite;
  transform-origin: center 2px;
  fill: currentColor;
}
#loading-indicator path:nth-child(12) {
stroke-dasharray: 1483.380859375;
stroke-dashoffset: 1483.380859375;
}
#loading-indicator path.piece-w {
  animation: rotate-b 4s infinite;
  transform-origin: 450px 175px;
  fill: currentColor;
}
#loading-indicator path:nth-child(14) {
stroke-dasharray: 282.2522277832031;
stroke-dashoffset: 282.2522277832031;
}
#loading-indicator path.piece-d {
  animation: rotate-b 4s infinite;
  transform-origin: 1000px 200%;
  fill: currentColor;
}

@keyframes rotate-b{
  0%{ transform: rotate(90deg); }
	100%{ transform: rotate(0deg); }	
}
@keyframes rotate-t2{
  0%{ transform: rotate(90deg); }
  70%{ transform: rotate(300deg); }	
	100%{ transform: rotate(0deg); }	
}
@keyframes rotate-e{
  0%{ transform: rotate(180deg); }
	100%{ transform: rotate(0deg); }	
}

@keyframes draw {
    to {
      stroke-dashoffset: 0;
    }
  }


.infobox{
    order: 2;
    max-width: 600px !important;
}
.shoppa{
    order: 1;
    min-width: 350px !important;
}

@media(max-width: 1046px){
    .infobox{
        order: 1;
    }
    .shoppa{
        order: 2;
    }
    .top-box-webshop{
        padding-bottom: 300px !important;
    }
}
@media(max-width: 550) {
    .shoppa{
        width: 350px !important;
    }
    
}
.top-box-webshop{
    padding-bottom: 100px !important;
    min-height: calc(100vh + 90px) !important;
}

.logo-box{
    min-height: 250px !important;
    padding-top: 100px;
}
.logo-image{
    max-width: 800px;
}
.shoppa{
    width: 450px !important;
}



#sd25-webshop{
    &--container{
        margin-top: -70px;
        display: flex;
        flex-flow: column nowrap;
        max-width: 300px;
        max-height: 70vh;
        z-index: 2;
        align-items: center;
        margin-left: 100px;

        & .slide-in{
            animation: slide-in 3s;
        }
        & .slide-out{
            animation: slide-out 3s;
        }
    }
    &--het-sjateau{
        max-width: 300px;
        max-height: 70vh;
        z-index: 2;
        align-items: center;
    }
    &--code-reminder{
        position: absolute;
        left:50%;
        margin-left: -175px;
        margin-top: 300px;
        

        &--praatwolk{
            background: url('../media/images/SD25/praatwold.png');
            -webkit-background-size: 260px 100px;
            -moz-background-size: 260px 110px;
            -ms-background-size: 260px 110px;
            -o-background-size: 260px 110px;
            background-position: 50%;
            background-size: 200px 70px;
            background-repeat: no-repeat;

            padding: 40px;
            position: relative;
            z-index: 100;
            transform: rotate(330deg);
            margin-left: -60px;

            font-size: 0.7em;
        }
        &--tim-en-maarten{
            width: 150px;
            transform: rotate(310deg);
            margin-top: 10px;
    
        }
        
    }

    
}

#shop-frame{
    margin-top: -15px !important;
    z-index: 1;
    }


@keyframes slide-in {
    from {
        translate: 0 150vh;
        scale: 100% 1;
      }
    
      to {
        translate: 0 0;
        scale: 100% 1;
      }
}
@keyframes slide-out {
    from {
        translate: 0 0;
        scale: 100% 1;
    }
    
    to {
        translate: 150vh 0;
        scale: 100% 1;
    }
}
.top-box-webshop{
    min-height: 100vh !important;
}

.infobox{
    order: 2;
    height: 100%;
    max-width: 1000px;
}
.shoppa{
    order: 1;
    min-width: 350px !important;
    justify-content: center;
    margin: 0 2em 0 0;
}

@media(max-width: 1046px){
    .infobox{
        order: 1;
    }
    .shoppa{
        order: 2;
        margin: 0 0 0 0;
    }
    .top-box-webshop{
        padding-bottom: 300px !important;
    }
}
@media(max-width: 550) {
    .shoppa{
        width: 350px !important;
    }
    
}

.shoppa{
    width: 450px !important;
}

.webshop-promo{
    max-width: 100%;
}